import React from 'react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    RedditShareButton,
    TelegramShareButton
} from 'react-share';

import {
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    RedditIcon,
    TelegramIcon,
} from 'react-share';

import classNames from './Share.module.css';

const Share = ({url, title, description}) => (
    <div>
        <FacebookShareButton
            url={url}
            className={classNames.SocialMediaShareButton}
            quote={description}
            href={url}
        >
            <FacebookIcon
                size={32}
                round={true}
                className={classNames.postSocialButton}
            />
        </FacebookShareButton>

        <TwitterShareButton
            url={url}
            className={classNames.SocialMediaShareButton}
            title={title}
        >
            <TwitterIcon
                size={32}
                round={true}
                className={classNames.postSocialButton}
            />
        </TwitterShareButton>

        <LinkedinShareButton
            url={url}
            className={classNames.SocialMediaShareButton}
            title={title}
            description={description}
        >
            <LinkedinIcon
                size={32}
                round={true}
                className={classNames.postSocialButton}
            />
        </LinkedinShareButton>

        <RedditShareButton
            url={url}
            className={classNames.SocialMediaShareButton}
            title={title}
        >
            <RedditIcon
                size={32}
                round={true}
                className={classNames.postSocialButton}
            />
        </RedditShareButton>

        <WhatsappShareButton
            url={url}
            className={classNames.SocialMediaShareButton}
            title={title}
        >
            <WhatsappIcon
                size={32}
                round={true}
                className={classNames.postSocialButton}
            />
        </WhatsappShareButton>
        <TelegramShareButton
            url={url}
            className={classNames.SocialMediaShareButton}
            title={title}
        >
            <TelegramIcon
                size={32}
                round={true}
                className={classNames.postSocialButton}
            />
        </TelegramShareButton>
    </div>
);

export default Share;

