import { Component } from 'react'

export default class ToggleProvider extends Component {
  state = {
    isVisible: false
  }

  show = () => this.setState({ isVisible: true })
  hide = () => this.setState({ isVisible: false })
  toggle = () => this.setState({ isVisible: !this.state.isVisible })

  render() {
    return this.props.render(this.state.isVisible, {
      toggle: this.toggle,
      show: this.show,
      hide: this.hide
    })
  }
}
