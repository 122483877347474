import React from 'react'
import Image from 'gatsby-image'
import classNames from './Author.module.css'
import defaultAvatar from '../img/defaultAvatar.png'

const Author = ({ name, description, avatar }) =>
  name && (
    <div className={classNames.authorContainer}>
      <div className={classNames.authorAvatar}>
        {avatar ? <Image fixed={avatar.childImageSharp.resolutions} /> : <img alt={name} src={defaultAvatar} />}
      </div>
      <div className={classNames.authorDetails}>
        <p className={`${classNames.authorNameContainer} ${!description ? classNames.noDescription : ''}`}>
          By <span className={classNames.authorName}>{name}</span>
        </p>
        {description && <p className={classNames.authorDescription}>{description}</p>}
      </div>
    </div>
  )

export default Author
