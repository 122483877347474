import React from 'react'
import Link from 'gatsby-link'
import Image from 'gatsby-image'
import styles from './ArticleThumbnail.module.css'
import TagTooltip from './TagTooltip'
import { stringTrunc } from '../helpers'

const ArticleThumbnail = ({
  title,
  date,
  tags,
  summary,
  authorName,
  category,
  thumbnail,
  link,
  selectedTag,
  columns,
  headerImage,
  isVisible = true,
}) => (
  <div
    className={`${styles[`articleThumbnailContainer${columns > 1 ? `Cols${columns}` : ''}`]} ${
      isVisible ? styles.visible : ''
    }`}
  >
    <div className={`${styles.content} ${thumbnail && columns !== 3 ? styles.withImage : ''}`}>
      <div>
        <Link to={`/categories/${encodeURIComponent(category)}`} className={styles.category}>
          {category}
        </Link>
      </div>
      <div className={styles.title}>
        <Link to={link}>{title}</Link>
      </div>
      {authorName && (
        <div className={styles.author}>
          By <span>{authorName}</span>
        </div>
      )}
      {summary && <p className={styles.summary}>{stringTrunc(summary, 100)}</p>}
      {tags && <TagTooltip selectedTag={selectedTag} tags={tags} title={title} />}
    </div>

    {(thumbnail || headerImage) && (
      <div className={styles.thumbnail}>
        <Image
          style={{
            width: '100%',
            display: 'block',
          }}
          imgStyle={{
            width: 'auto',
            transform: 'translate(-50%, -50%)',
            marginLeft: '50%',
            marginTop: '50%',
          }}
          fixed={thumbnail ? thumbnail.childImageSharp.resolutions : headerImage.childImageSharp.resolutions}
        />
      </div>
    )}
  </div>
)

export default ArticleThumbnail
