import React from 'react'
import ToggleProvider from '../containers/ToggleProvider'
import ArticleThumbnail from './ArticleThumbnail'
import { mapMardkownRemarkToArticle } from '../helpers'

import classNames from './AuthorArticles.module.css'

export default ({ authorArticles, authorName }) => {
  return (
    authorArticles && (
      <ToggleProvider
        render={(isVisible, { toggle }) => (
          <div className={classNames.authorArticlesContainer}>
            <div
              className={
                isVisible
                  ? classNames.articlesContainer
                  : classNames.articlesContainerInvisible
              }
            >
              {authorArticles.edges.map(article => (
                <ArticleThumbnail
                  key={article.node.fields.slug}
                  {...mapMardkownRemarkToArticle(article)}
                  columns={authorArticles.edges.length === 1 ? 1 : 3}
                />
              ))}
            </div>

            <div className={classNames.authorArticlesButtonContainer}>
              <button
                className={
                  !isVisible
                    ? classNames.authorArticlesButton
                    : classNames.authorArticlesButtonInvisible
                }
                onClick={toggle}
              >
                {isVisible ? `Hide` : `More from ${authorName}`}
              </button>
            </div>
          </div>
        )}
      />
    )
  )
}
