import React, { Component } from 'react'
import { Portal } from 'react-portal'
import Link from 'gatsby-link'
import ToggleProvider from '../containers/ToggleProvider'
import classNames from './TagTooltip.module.css'

export default class TagTooltip extends Component {
  state = {
    offset: undefined
  }

  container = null
  tooltipBar = null
  timeout = null

  onMouseLeave = ( hide ) =>
    () =>
      this.timeout = setTimeout( () => hide(), 600 )

  onMouseEnter = () =>
    ( this.timeout ) ? this.timeout = clearTimeout( this.timeout )
      : null

  calculateOffset = () => {
    const BTN_WIDTH = 30
    const BTN_HEIGHT = 20

    this.setState({ offset: undefined })

    const tagElements = this.container.querySelectorAll(`.${classNames.tag}`)

    let incrementalWidth = BTN_WIDTH

    for (let i = 0; i < tagElements.length; i++) {
      const tag = tagElements[i]

      incrementalWidth += tag.offsetWidth

      if (incrementalWidth >= this.container.offsetWidth) {
        return this.setState({ offset: i - 1 }, () => {
          if (this.tooltipBar) {
            const tooltipButton = this.container.querySelector(
              `.${classNames.tagTooltipButton}`
            )
            const { top, right } = tooltipButton.getBoundingClientRect()

            this.tooltipBar.style.maxWidth = `${
              document.documentElement.offsetWidth
            }px`
            this.tooltipBar.style.top = `${top +
              window.scrollY +
              BTN_HEIGHT +
              10}px`
            this.tooltipBar.style.right = `${document.documentElement
              .offsetWidth - right}px`
          }
        })
      }
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.calculateOffset()

      window.addEventListener('resize', this.calculateOffset)
    }, 0)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateOffset)
  }

  render() {
    const { offset } = this.state
    const { tags, selectedTag, title } = this.props

    return (
      <div ref={ref => (this.container = ref)}>
        {tags.slice(0, offset).map(tag => (
          <Link
            to={`/tags/${tag}`}
            key={`tag-${title}-${tag}`}
            className={`${classNames.tag} ${
              tag === selectedTag ? classNames.selectedTag : ''
            }`}
          >
            {`#${tag}`}
          </Link>
        ))}
        <ToggleProvider
          render={(isVisible, { show, hide, toggle }) =>
            offset !== undefined && (
              <div
                className={classNames.tagTooltipButton}
                onClick={toggle}
                onMouseEnter={ show }
                onMouseLeave={ this.onMouseLeave( hide ) }
              >
                ...
                <Portal>
                  <div
                    ref={ref => (this.tooltipBar = ref)}
                    className={`${classNames.tagTooltipContainer} ${
                      !isVisible ? classNames.tagTooltipContainerInvisible : ''
                    }`}
                  >
                    <div
                      className={classNames.tagTooltip}
                      onMouseEnter={this.onMouseEnter}
                      onMouseLeave={hide}>
                      {tags.slice(offset).map(tag => (
                        <Link
                          key={`tag-tooltip-${title}-${tag}`}
                          to={`/tags/${tag}`}
                          className={classNames.tagTooltipTag}
                        >
                          {`#${tag}`}
                        </Link>
                      ))}
                    </div>
                  </div>
                </Portal>
              </div>
            )
          }
        />
      </div>
    )
  }
}
