import React from 'react'
import ArticleThumbnail from './ArticleThumbnail'
import { mapMardkownRemarkToArticle } from '../helpers'
import classNames from './SuggestedArticles.module.css'

export default ({ suggestedArticles }) => {
  return (
    suggestedArticles && (
      <div className={classNames.suggestedArticlesContainer}>
        <div className={classNames.articlesTitle}>Stories you might like:</div>
        <div className={classNames.articlesContainer}>
          {suggestedArticles.edges.map(article => (
            <ArticleThumbnail
              key={article.node.fields.slug}
              {...mapMardkownRemarkToArticle(article)}
              columns={suggestedArticles.edges.length === 1 ? 1 : 3}
            />
          ))}
        </div>
      </div>
    )
  )
}
