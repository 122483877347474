import React from 'react'
import Link from 'gatsby-link'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Author from './Author'
import AuthorArticles from './AuthorArticles'
import SuggestedArticles from './SuggestedArticles'
import classNames from './Post.module.css'
import { SITE_TITLE } from '../constants'
import Layout from './layout'
import Share from './Share';

export const query = graphql`
  query PostQuery($slug: String!, $category: String!, $author: String) {
    article: markdownRemark(fields: { slug: { eq: $slug } }) {
      ...pageFields
    }
    categoryArticles: allMarkdownRemark(
      filter: {
        frontmatter: { category: { eq: $category } }
        fields: { slug: { ne: $slug } }
      }
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          ...pageFields
        }
      }
    }
    authorArticles: allMarkdownRemark(
      filter: {
        frontmatter: { authorName: { eq: $author } }
        fields: { slug: { ne: $slug } }
      }
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          ...pageFields
        }
      }
    }
  }
`

export default ({
  data: {
    article: {
      frontmatter: {
        title,
        summary,
        authorName,
        authorDescription,
        authorAvatar,
        headerImage,
        category,
        date,
        thumbnail
      },
      html,
      fields: {
        tags
      }
    },
    categoryArticles,
    authorArticles
  },
  location: { href }
}) => {
  return (
    <Layout>
      <Helmet
        title={`${title} -  ${SITE_TITLE}`}
        meta={[
          { name: 'keywords', content: tags },
          { name: 'description', content: summary },
          { property: 'og:description', content: summary },
          { property: 'twitter:description', content: summary },
          { property: 'og:title', content: `${title} - ${SITE_TITLE}` },
          { property: 'twitter:title', content: `${title} - ${SITE_TITLE}` },
          { property: 'og:url', content: href },
          { property: 'twitter:url', content: href },
          { property: 'og:type', content: 'website' },
          { property: 'twitter:card', content: 'summary_large_image' },
          ...(thumbnail ? [
            { property:"og:image", content: thumbnail.childImageSharp.resolutions.src },
            { property: "twitter:image", content: thumbnail.childImageSharp.resolutions.src }
          ] : []),
        ]}
      />

      {headerImage && (
        <div
          className={classNames.splash}
          style={{
            backgroundImage: `url(${
              headerImage.childImageSharp.resolutions.src
            })`
          }}
        />
      )}
      <div className={classNames.postContainer}>
        <h1 className={classNames.title}>{title}</h1>

        <Author
          name={authorName}
          description={authorDescription}
          avatar={authorAvatar}
        />

        <div className={classNames.categoryDateContainer}>
          <div className={classNames.backButtonWrap}>
            <Link to="/">&lt; Back to list</Link>
          </div>
          <div className={classNames.categoryColumn}>
            <Link to={`/categories/${encodeURIComponent(category)}`}>
              {category}
            </Link>{' '}
          </div>
        </div>

        <div
          className={classNames.contentContainer}
          dangerouslySetInnerHTML={{ __html: html }}
        />

        <Share
            url={href}
            title={`${title} -  ${SITE_TITLE}`}
            description={summary}
        />

        <AuthorArticles
          authorArticles={authorArticles}
          authorName={authorName}
        />

        <SuggestedArticles suggestedArticles={categoryArticles} />
      </div>
    </Layout>
  )
}
